import type { FunctionComponent } from 'react'

import type {
    BentoPieceRectangleFourPartsFragment,
    BentoPieceRectangleFragment,
    BentoPieceRectangleTestimonialFragment,
    BentoPieceRectangleThreePartsFragment,
} from '../../../graphql/queries/GetBentoOne.graphql'
import { BentoRectangle } from './bento-rectangle'
import { BentoRectangleFourParts } from './bento-rectangle-four-parts'
import { BentoRectangleTestimonial } from './bento-rectangle-testimonial'
import { BentoRectangleThreeParts } from './bento-rectangle-three-parts'

const BentoPieceRectangle: FunctionComponent<Props> = ({ rectangle }) => {
    return (
        <>
            {rectangle?.type === 'BentoPieceRectangle' && (
                <BentoRectangle
                    headingImage={rectangle?.headingImage}
                    headingMd={rectangle?.headingMd}
                    headingColor={rectangle?.headingColor}
                    copy={rectangle?.copy}
                    copyColor={rectangle?.copyColor}
                    showFdicVisaImage={rectangle?.showFdicVisaImage}
                    showWinnersBadge={rectangle?.showWinnersBadge}
                    button={rectangle?.button}
                    backgroundType={rectangle?.backgroundType}
                    backgroundColor={rectangle?.backgroundColor}
                    image={rectangle?.image}
                    backgroundImage={rectangle?.backgroundImage}
                    backgroundImageMobile={rectangle?.backgroundImageMobile}
                />
            )}

            {rectangle?.type === 'BentoPieceRectangleTestimonial' && (
                <BentoRectangleTestimonial
                    heading={rectangle?.heading}
                    headingMd={rectangle?.headingMd}
                    headingColor={rectangle?.headingColor}
                    subHeading={rectangle?.subHeading}
                    subHeadingColor={rectangle?.subHeadingColor}
                    copy={rectangle?.copy}
                    copyColor={rectangle?.copyColor}
                    copyFontSize={rectangle?.copyFontSize}
                    alignText={rectangle?.alignText}
                    backgroundColor={rectangle?.backgroundColor}
                />
            )}

            {rectangle?.type === 'BentoPieceRectangleFourParts' && (
                <BentoRectangleFourParts
                    copyOne={rectangle?.copyOne}
                    copyTwo={rectangle?.copyTwo}
                    copyThree={rectangle?.copyThree}
                    copyFour={rectangle?.copyFour}
                />
            )}

            {rectangle?.type === 'BentoPieceRectangleThreeParts' && (
                <BentoRectangleThreeParts parts={rectangle?.parts} />
            )}
        </>
    )
}

export default BentoPieceRectangle

type Props = Omit<BentoPieceRectangleFragment, 'sys' | 'type'> & {
    index?: number
    rectangle?:
        | BentoPieceRectangleFragment
        | BentoPieceRectangleTestimonialFragment
        | BentoPieceRectangleFourPartsFragment
        | BentoPieceRectangleThreePartsFragment
}
