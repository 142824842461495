import type { FunctionComponent } from 'react'
import type { CustomArrowProps } from 'react-slick'
import Slider from 'react-slick'

import type { BentoOneFragment } from '../../../../graphql/queries/GetBentoOne.graphql'
import BentoPieceRectangle from '../../pieces/bento-piece-rectangle'
import styles from './bento-one.module.scss'

export const BentoOne: FunctionComponent<Props> = ({ pieces }) => {
    const NextArrow = ({
        currentSlide,
        slideCount,
        ...props
    }: CustomArrowProps) => <div {...props} />

    const PrevArrow = ({
        currentSlide,
        slideCount,
        ...props
    }: CustomArrowProps) => <div {...props} />

    return (
        <div className={styles.container}>
            <div className={styles.flexContainer}>
                <Slider
                    accessibility
                    autoplay
                    autoplaySpeed={7000}
                    className={styles.carousel}
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                >
                    {pieces?.items.map((item, index) => {
                        return (
                            <div key={index}>
                                <BentoPieceRectangle rectangle={item} />
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default BentoOne

type Props = Omit<BentoOneFragment, 'sys' | 'type'> & {
    index?: number
}
